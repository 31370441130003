import React, {/*  useEffect, useState */ } from 'react';
import { useFetchGifs } from '../hooks/useFetchGifs';
import { GifGridItem } from './GifGridItem';
import PropTypes from 'prop-types';

export const GifGrid = ({ category }) => {


    const {images,loading} = useFetchGifs(category);

    

    return (
        <>
            <h3 className="animate__animated animate__backInDown">{ category }</h3>            
            {loading && <p className="animate__animated animate__flash">Loading</p>}
            <div className="divCard-Grid">            
                {
                    images.map( img => (
                        <GifGridItem
                            key={ img.id } 
                            { ...img }
                        />
                    ))
                }            
            </div>
        </>
    )
}

GifGrid.propTypes = {
    category: PropTypes.string.isRequired
}