import React from 'react'

function Footer() {
    return (
        <footer>
            Developer by <a href="https://adangarnica.com"><em>Adan Garnica Albor</em></a>
        </footer>
    )
}

export default Footer;
