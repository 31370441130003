import React, { useState } from 'react'
import { AddCategory } from './components/AddCategory';
import { GifGrid } from './components/GifGrid';
import Footer from "./components/Footer";

export const GifExpertApp = ({ defaultCategories = ['Dragon Ball']}) => {

    /* const [categories, setCategories] = useState(['Avatar']);  */
    const [categories, setCategories] = useState(defaultCategories); 

    return(
      <>
        <h1 className="animate__animated animate__wobble">GifExpertApp</h1>        
        <AddCategory setCategories= { setCategories }/>
        <small>*Escribe la categoria que quieres mostrar y presiona "Enter"</small>
        <hr/>        
        <ol>
            { 
                categories.map( category => (
                    <GifGrid 
                        key={ category }
                        category={ category }
                    />
                ))
            }
        </ol>
        <Footer />
      </>
    );
  }