//Los Hooks no son mas que funciones

import { useEffect, useState } from "react"
import { getGifs } from "../helpers/getGifs";

export const useFetchGifs = (category) => {
  const [state, setState] = useState({
      images: [],
      loading: true
  });

  useEffect(()=>{
      getGifs(category)
      .then( img => setState({
          images: img,
          loading: false
      }) )
  },[category]);

  return state;
}