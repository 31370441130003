import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const AddCategory = ( { setCategories } ) => {

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        console.log('HadleInputChange show');
    }

    const handleSubmit = (e) => {
        e.preventDefault();   
        console.log('handlesubmit');
        if(inputValue.trim().length > 2){
            setCategories( ctgs => [inputValue,...ctgs] )
            setInputValue('');
        }
        console.log('Submit Echo');
    }

    const handleClean = () => {
        setCategories([]);
        setInputValue('');
    }

    return(
        <div className="divForm">
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    name="name"
                    value={inputValue}
                    onChange= {handleInputChange}                
                />                            
            </form>
            <button title='Buscar' onClick={handleSubmit} className="btnSearch"><i className="fas fa-search-plus"></i></button>            
            <button onClick={handleClean} title='Eliminar Todo' className="btnDelete"><i className="fas fa-trash-alt"></i></button>                        
        </div>
    );
}

AddCategory.propTypes = {
    setCategories : PropTypes.func.isRequired
}