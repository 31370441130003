import React from 'react';
import PropTypes from 'prop-types';

export const GifGridItem = ( {title, url} ) => {
    
    //console.log(id, title, url);

    return (
        <div className="divCard animate__animated animate__zoomInDown">
            <img src={url} alt={title} />
            <p>{title}</p>
        </div>
    )
}

GifGridItem.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}